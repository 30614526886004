<template>
  <div id="specific-settings">
    <!-- options builder -->

    <OptionsBuilder
      v-if="showOptionsBuilder"
      :field-type="fieldType"
      :options-type="optionsType"
      :master-form-id="masterFormId"
      :master-form-column="masterFormColumn"
      :master-form-parent-column="masterFormParentColumn"
      :repository-id="repositoryId"
      :repository-field="repositoryField"
      :repository-field-parent="repositoryFieldParent"
      :predefined-table="predefinedTable"
      :predefined-table-column="predefinedTableColumn"
      :custom-options="customOptions"
      :separate-options-using="separateOptionsUsing"
      :allow-to-add-new-options="allowToAddNewOptions"
      :options-per-line="optionsPerLine"
      :panels="panels"
      :settings-for="settingsFor"
      :field-id="fieldId"
      :default-value-in-select-field="defaultValueInSelectField"
      :is-same-master="isSameMaster"
      :master-form-condition-column="masterFormConditionColumn"
    />

    <!-- ... -->

    <!-- default value -->

    <SingleChoiceField
      v-if="showDefaultValue"
      :value="defaultValue"
      label="fill value"
      :options="_defaultValueOptions"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateDefaultValue"
    />

    <!-- ... -->

    <!-- auto generate value - SHORT_TEXT -->

    <div
      v-if="fieldType === 'SHORT_TEXT' && defaultValue === 'AUTO_GENERATE'"
      class="row items-start q-pa-md"
    >
      <!-- prefix -->

      <ValidationProvider
        v-slot="{ errors }"
        name="prefix"
        :rules="{ required: true }"
        class="col"
      >
        <TextField
          :value="autoGenerateValue.prefix"
          label="prefix"
          is-mandatory
          :error="errors[0]"
          @input="
            (value) =>
              updateAutoGenerateValue({
                prefix: value,
                suffix: autoGenerateValue.suffix,
              })
          "
        />
      </ValidationProvider>

      <!-- ... -->

      <div class="q-mx-sm"></div>

      <!-- suffix -->

      <SelectField
        :value="autoGenerateValue.suffix"
        label="suffix"
        is-mandatory
        :options="autoGenerateSuffixOptions"
        :is-clearable="false"
        class="col"
        @input="
          (value) =>
            updateAutoGenerateValue({
              prefix: autoGenerateValue.prefix,
              suffix: value,
            })
        "
      />

      <!-- ... -->
    </div>

    <!-- ... -->

    <!-- custom default value - SHORT_TEXT -->

    <ValidationProvider
      v-if="
        (fieldType === 'SHORT_TEXT' ||
          fieldType === 'PASSWORD' ||
          fieldType === 'COUNTRY_CODE') &&
        defaultValue === 'CUSTOM'
      "
      v-slot="{ errors }"
      name="default value"
      :rules="{ min: minimum || false, max: maximum || false }"
    >
      <TextField
        :value="customDefaultValue"
        label="custom default value"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- default value - LONG_TEXT -->

    <ValidationProvider
      v-if="fieldType === 'LONG_TEXT' && defaultValue === 'CUSTOM'"
      v-slot="{ errors }"
      name="default value"
      :rules="{ min: minimum || false, max: maximum || false }"
    >
      <TextAreaField
        :value="customDefaultValue"
        label="default value"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- default value - NUMBER -->

    <ValidationProvider
      v-if="
        ['NUMBER', 'COUNTER', 'CURRENCY'].includes(fieldType) &&
        defaultValue === 'CUSTOM'
      "
      v-slot="{ errors }"
      name="default value"
      :rules="{ min_value: minimum || false, max_value: maximum || false }"
    >
      <NumberField
        :value="customDefaultValue"
        label="default value"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- custom default value - DATE -->

    <ValidationProvider
      v-if="fieldType === 'DATE' && defaultValue === 'CUSTOM'"
      v-slot="{ errors }"
      name="default value"
      :rules="{ minDate: minimum || false, maxDate: maximum || false }"
    >
      <DateField
        :value="customDefaultValue"
        label="custom default value"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- custom default value - TIME -->

    <ValidationProvider
      v-if="fieldType === 'TIME' && defaultValue === 'CUSTOM'"
      v-slot="{ errors }"
      name="default value"
      :rules="{ minTime: minimum || false, maxTime: maximum || false }"
    >
      <TimeField
        :value="customDefaultValue"
        label="custom default value"
        class="q-pa-md"
        :error="errors[0]"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- custom default value - DATE_TIME -->

    <ValidationProvider
      v-if="fieldType === 'DATE_TIME' && defaultValue === 'CUSTOM'"
      v-slot="{ errors }"
      name="default value"
      :rules="{ minDate: minimum || false, maxDate: maximum || false }"
    >
      <DateTimeField
        :value="customDefaultValue"
        label="custom default value"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateCustomDefaultValue"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- default value - SINGLE_SELECT | SINGLE_CHOICE -->

    <SelectField
      v-if="
        ['SINGLE_SELECT', 'SINGLE_CHOICE'].includes(fieldType) &&
        optionsType === 'CUSTOM'
      "
      :value="customDefaultValue"
      label="default value"
      :options="_customOptions"
      class="q-pa-md"
      @input="updateCustomDefaultValue"
    />

    <!-- ... -->

    <!-- default value - MULTI_SELECT | MULTIPLE_CHOICE -->

    <MultiSelectField
      v-if="
        ['MULTI_SELECT', 'MULTIPLE_CHOICE'].includes(fieldType) &&
        optionsType === 'CUSTOM'
      "
      :value="customDefaultValue"
      label="default value"
      :options="_customOptions"
      class="q-pa-md"
      @input="updateCustomDefaultValue"
    />

    <!-- ... -->

    <!-- column total -->

    <SingleChoiceField
      v-if="_showColumnTotal"
      :value="showColumnTotal"
      label="show column total"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateShowColumnTotal"
    />

    <!-- ... -->

    <!-- allow multiple files -->

    <SingleChoiceField
      v-if="fieldType === 'FILE_UPLOAD'"
      :value="allowMultipleFiles"
      label="allow multiple files"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateAllowMultipleFiles"
    />

    <!-- ... -->

    <!-- rating icon -->

    <SingleChoiceField
      v-if="fieldType === 'RATING'"
      :value="ratingIcon"
      label="rating icon"
      :options="ratingIcons"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateRatingIcon"
    />

    <!-- ... -->

    <!-- rating icon count -->

    <SelectField
      v-if="fieldType === 'RATING'"
      :value="ratingIconCount"
      label="rating icon count"
      :options="ratingIconCounts"
      :is-clearable="false"
      class="q-pa-md"
      @input="updateRatingIconCount"
    />

    <!-- ... -->

    <!-- allow half rating -->

    <SingleChoiceField
      v-if="fieldType === 'RATING'"
      :value="allowHalfRating"
      label="allow half rating"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateAllowHalfRating"
    />

    <!-- ... -->

    <!-- allow multiple signatures -->

    <SingleChoiceField
      v-if="fieldType === 'SIGNATURE'"
      :value="allowMultipleSignatures"
      label="allow multiple signatures"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateAllowMultipleSignatures"
    />

    <!-- ... -->

    <!-- table columns builder -->

    <ValidationProvider
      v-if="fieldType === 'TABLE'"
      v-slot="{ errors }"
      name="columns"
      :rules="{ required: true }"
    >
      <TableColumnsBuilder
        :value="tableColumns"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateTableColumns"
        @column-settings="showColumnSettings"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- default value -->

    <SingleChoiceField
      v-if="fieldType === 'TABLE'"
      :value="defaultValue"
      label="fill value"
      :options="_defaultValueOptions"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateDefaultValue"
    />

    <!-- ... -->

    <!-- master table -->

    <div
      v-if="defaultValue === 'MASTER' && fieldType === 'TABLE'"
      class="q-pa-md"
    >
      <!-- <FormFieldLabel
        label="Parent Field (Filter based on parent field value selection)"
        is-mandatory
        class="field-label q-pt-md"
      /> -->

      <div class="q-mb-md">
        <SelectField
          is-mandatory
          placeholder="Parent Field"
          label="Parent Field (Filter based on parent field value selection)"
          :value="masterFormParentColumn"
          :is-clearable="true"
          :options="panelFields()"
          @input="updateMasterFormParentColumn"
        />
      </div>

      <FormFieldLabel label="master table" is-mandatory class="field-label" />
      <BaseSeparator class="q-mb-md" />
      <div class="row q-col-gutter-sm">
        <!-- master table -->

        <ValidationProvider
          v-slot="{ errors }"
          name="table"
          :rules="{ required: true }"
          class="col"
        >
          <!-- <SelectField
            is-mandatory
            placeholder="Table"
            :error="errors[0]"
            :value="masterFormId"
            :is-clearable="true"
            :options="masterForms"
            :is-readonly="true"
          /> -->
          <TextField
            is-mandatory
            :value="masterFormName"
            placeholder="Table"
            :error="errors[0]"
            :is-clearable="true"
            :is-readonly="true"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- column -->

        <div class="q-pa-sm col-12">
          <FormFieldLabel label="Columns" class="field-label" is-mandatory />
          <BaseScrollbar>
            <div id="master-table">
              <table>
                <thead>
                  <tr>
                    <th class="medium">Table Column</th>
                    <th class="large">Master Column</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(row, index) in masterFormTableColumns"
                    :key="index"
                  >
                    <td>
                      <div class="row">
                        <SelectField
                          v-model="row.tableColumn"
                          class="col"
                          :options="_tableColumns"
                          :is-clearable="false"
                        />
                      </div>
                    </td>
                    <td>
                      <SelectField
                        v-model="row.masterColumn"
                        :options="_masterFormColumns"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </BaseScrollbar>
        </div>

        <!-- ... -->
      </div>
    </div>
    <!-- .... -->

    <!-- table row choose type -->
    <SingleChoiceField
      v-if="fieldType === 'TABLE'"
      :value="tableRowChooseType"
      label="table row selection"
      :options="tableRowChooseTypes"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateTableRowChooseType"
    />
    <!-- ... -->

    <!-- table rows type -->

    <SingleChoiceField
      v-if="fieldType === 'TABLE'"
      :value="tableRowsType"
      label="table rows type"
      :options="tableRowsTypes"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateTableRowsType"
    />

    <!-- ... -->

    <!-- table fixed row count -->

    <ValidationProvider
      v-if="fieldType === 'TABLE' && tableRowsType === 'FIXED'"
      v-slot="{ errors }"
      name="row count"
      :rules="{ required: true, min_value: 1, max_value: 100 }"
    >
      <CounterField
        is-mandatory
        :value="tableFixedRowCount"
        label="table fixed row count"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateTableFixedRowCount"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- matrix columns builder -->

    <ValidationProvider
      v-if="fieldType === 'MATRIX'"
      v-slot="{ errors }"
      name="columns"
      :rules="{ required: true }"
    >
      <MatrixVectorsBuilder
        :value="matrixColumns"
        label="columns"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateMatrixColumns"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- matrix rows builder -->

    <ValidationProvider
      v-if="fieldType === 'MATRIX'"
      v-slot="{ errors }"
      name="rows"
      :rules="{ required: true }"
    >
      <MatrixVectorsBuilder
        :value="matrixRows"
        label="rows"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateMatrixRows"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- matrix type -->

    <MatrixType
      v-if="fieldType === 'MATRIX'"
      :value="matrixType"
      class="q-pa-md"
      @input="updateMatrixType"
      @matrix-type-settings="showMatrixTypeSettings"
    />

    <!-- ... -->

    <!-- fib fields -->

    <FibFields
      v-if="fieldType === 'FILL_IN_THE_BLANKS'"
      :fib-fields="fibFields"
      class="q-pa-md"
    />

    <!-- ... -->

    <!-- tabs builder -->

    <TabsBuilder
      v-if="fieldType === 'TABS'"
      :value="tabList"
      :secondary-panels="_secondaryPanels"
      class="q-pa-md"
      @input="updateTabList"
    />

    <!-- ... -->

    <!-- popup trigger type -->

    <SingleChoiceField
      v-if="fieldType === 'POPUP'"
      :value="popupTriggerType"
      label="trigger type"
      :options="popupTriggerTypes"
      :options-per-line="3"
      class="q-pa-md"
      @input="updatePopupTriggerType"
    />

    <!-- ... -->

    <!-- secondary panel -->

    <ValidationProvider
      v-if="fieldType === 'POPUP'"
      v-slot="{ errors }"
      name="secondary panel"
      :rules="{ required: true }"
    >
      <SelectField
        is-mandatory
        label="secondary panel"
        tooltip="selected secondary panel will be displayed inside the popup"
        :value="secondaryPanel"
        :options="_secondaryPanels"
        :is-clearable="false"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateSecondaryPanel"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- mapped field -->

    <SelectField
      v-if="fieldType === 'POPUP'"
      label="mapped field"
      tooltip="mapped field's value will be displayed instead of default label"
      :value="mappedFieldId"
      :options="secondaryPanelFields"
      :is-clearable="false"
      class="q-pa-md"
      @input="updateMappedFieldId"
    />

    <!-- ... -->

    <!-- mapped column -->

    <SelectField
      v-if="fieldType === 'POPUP'"
      label="mapped column"
      :value="mappedColumnId"
      :options="secondaryPanelFieldColumns"
      :is-clearable="false"
      class="q-pa-md"
      @input="updateMappedColumnId"
    />

    <!-- ... -->

    <!-- nested list max level -->

    <ValidationProvider
      v-if="fieldType === 'NESTED_LIST'"
      v-slot="{ errors }"
      name="max level"
      :rules="{ min_value: 1, max_value: 10 }"
    >
      <CounterField
        :value="nestedListMaxLevel"
        label="max level"
        :error="errors[0]"
        class="q-pa-md"
        @input="updateNestedListMaxLevel"
      />
    </ValidationProvider>

    <!-- ... -->

    <!-- nested list field type -->

    <NestedListFieldType
      v-if="fieldType === 'NESTED_LIST'"
      :value="nestedListFieldType"
      class="q-pa-md"
      @input="updateNestedListFieldType"
      @nested-list-type-settings="showNestedListTypeSettings"
    />

    <!-- ... -->

    <!-- nested list items per line -->

    <NestedListItemsPerLine
      v-if="fieldType === 'NESTED_LIST'"
      :value="nestedListItemsPerLine"
      :max-level="nestedListMaxLevel"
      class="q-pa-md"
      @input="updateNestedListItemsPerLine"
    />

    <!-- ... -->

    <!-- login authentication types -->

    <SingleChoiceField
      v-if="fieldType === 'LOGIN_AUTHENTICATION'"
      :value="loginType"
      label="login types"
      :options="loginTypes"
      :options-per-line="1"
      is-mandatory
      class="q-pa-md"
      @input="updateLoginType"
    />

    <!-- ... -->

    <!-- login authentication additional types-->

    <MultipleChoiceField
      v-if="fieldType === 'LOGIN_AUTHENTICATION'"
      :value="additionalLoginTypes"
      label="Social Login"
      :options="additionalLoginTypeOptions"
      :options-per-line="1"
      class="q-pa-md"
      @input="updateAdditionalLoginTypes"
    />
    <!-- ... -->

    <!-- master table -->

    <div
      v-if="defaultValue === 'MASTER' && fieldType !== 'TABLE'"
      class="q-pa-md"
    >
      <!-- <FormFieldLabel
        label="Parent Field (Filter based on parent field value selection)"
        is-mandatory
        class="field-label q-pt-md"
      /> -->

      <div class="q-mb-md">
        <SelectField
          is-mandatory
          placeholder="Parent Field"
          label="Parent Field (Filter based on parent field value selection)"
          :value="masterFormParentColumn"
          :is-clearable="true"
          :options="panelFields()"
          @input="updateMasterFormParentColumn"
        />
      </div>

      <FormFieldLabel label="master table" is-mandatory class="field-label" />
      <BaseSeparator class="q-mb-md" />
      <div class="row q-col-gutter-sm">
        <!-- master table -->

        <ValidationProvider
          v-slot="{ errors }"
          name="table"
          :rules="{ required: true }"
          class="col"
        >
          <!-- <SelectField
            is-mandatory
            placeholder="Table"
            :error="errors[0]"
            :value="masterFormId"
            :is-clearable="true"
            :options="masterForms"
            :is-readonly="true"
          /> -->
          <TextField
            is-mandatory
            :value="masterFormName"
            placeholder="Table"
            :error="errors[0]"
            :is-clearable="true"
            :is-readonly="true"
          />
        </ValidationProvider>

        <!-- ... -->

        <!-- column -->

        <ValidationProvider
          v-slot="{ errors }"
          name="column"
          :rules="{ required: true }"
          class="col"
        >
          <SelectField
            is-mandatory
            placeholder="Column"
            :error="errors[0]"
            :is-clearable="true"
            :value="masterFormColumn"
            :options="_masterFormColumns"
            @input="updateMasterFormColumn"
          />
        </ValidationProvider>

        <!-- ... -->
      </div>
    </div>
    <!-- .... -->

    <!-- parent date field -->
    <template v-if="defaultValue === 'PARENT_FIELD'">
      <div class="row">
        <SelectField
          label="parent date field"
          :value="parentDateField"
          :options="parentDateFields"
          :is-clearable="true"
          class="q-pa-md col-5"
          @input="updateParentDateField"
        />

        <CounterField
          v-if="parentDateField"
          :value="parentFieldsDays"
          label="How many days should be added from parent field date?"
          class="q-pa-md col"
          @input="updateParentFieldsDays"
        />
      </div>
    </template>
    <!-- .... -->

    <!-- parent field options -->
    <template v-if="defaultValue === 'PARENT_FIELD_OPTIONS'">
      <div class="row">
        <SelectField
          label="parent field"
          :value="parentOptionField"
          :options="parentOptionsFields"
          :is-clearable="true"
          class="q-pa-md col-12"
          @input="updateParentOptionField"
        />

        <!-- <CounterField
          v-if="parentDateField"
          :value="parentFieldsDays"
          label="How many days should be added from parent field date?"
          class="q-pa-md col"
          @input="updateParentFieldsDays"
        /> -->
      </div>
    </template>
    <!-- .... -->

    <!-- readonly controls -->

    <div v-if="parentOptionField" class="q-pa-md">
      <FormFieldLabel label="Options field" class="field-label" is-mandatory />
      <BaseScrollbar>
        <div id="master-table">
          <table>
            <thead>
              <tr>
                <th class="medium">Options</th>
                <th class="medium">Date Field</th>
                <th class="medium">Days</th>
                <th class="action">
                  <BaseActionButton
                    is-flat
                    class="q-ml-xs"
                    color="secondary"
                    icon="eva-plus-outline"
                    no-border
                    @click="addDateFieldOption"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(row, index) in dateFieldOptionSettings" :key="index">
                <td>
                  <div class="row">
                    <SelectField
                      v-model="dateFieldOptionSettings[index]['option']"
                      class="col"
                      :new-option="addOptionsIndate"
                      :options="parentFieldOptions"
                      :is-clearable="false"
                    />
                  </div>
                </td>
                <td>
                  <div class="row">
                    <SelectField
                      v-model="dateFieldOptionSettings[index]['dateField']"
                      class="col"
                      :options="parentDateFields"
                      :is-clearable="false"
                    />
                  </div>
                </td>
                <td>
                  <CounterField
                    v-model="dateFieldOptionSettings[index]['days']"
                    class="col"
                  />
                </td>
                <td class="action">
                  <BaseActionButton
                    is-flat
                    color="red"
                    icon="eva-close-outline"
                    no-border
                    @click="removeDateFieldOption(index)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseScrollbar>
    </div>

    <!-- ... -->

    <!-- get values from QR -->

    <SingleChoiceField
      v-if="fieldType === 'SHORT_TEXT' || fieldType === 'LONG_TEXT'"
      :value="addressField"
      label="Address Field"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateAddressField"
    />

    <div v-if="addressField" id="address-matching-field" class="q-pa-md">
      <FormFieldLabel
        label="Choose Controls to split the address to fill"
        class="field-label"
      />
      <table>
        <thead>
          <tr>
            <th class="medium">Value</th>
            <th class="large">Controls</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in addressMatchingFields" :key="index">
            <td>
              <TextField
                is-mandatory
                :value="addressMatchingFields[index]['addressColumn']"
                :is-clearable="true"
                :is-readonly="true"
              />
            </td>
            <td>
              <MultiSelectField
                v-model="addressMatchingFields[index]['selectFieldColumn']"
                :options="_panelFields"
                :is-clearable="false"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- ... -->

    <!-- get values from QR -->

    <SingleChoiceField
      v-if="showQROption"
      :value="qrValue"
      label="Read values from QR"
      :options="binaryOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateQRValue"
    />
    <!-- ... -->

    <!-- searchable field -->

    <SingleChoiceField
      v-if="showSearchableOption"
      :value="isSearchField"
      label="Search Field"
      :options="searchFieldOptions"
      :options-per-line="3"
      class="q-pa-md"
      @input="updateIsSearchField"
    />

    <template v-if="showSearchableOption && isSearchField === 'YES'">
      <SingleChoiceField
        :value="hasSameForm"
        label="Same Form"
        :options="searchFieldOptions"
        :options-per-line="2"
        class="q-pa-md"
        @input="updateHasSameForm"
      />

      <template v-if="hasSameForm === 'YES'">
        <MultiSelectField
          label="Form Sync Field"
          :value="formSyncField"
          :options="formFields"
          :is-clearable="false"
          class="q-pa-md"
          @input="updateFormSyncField"
        />
      </template>

      <template v-if="hasSameForm === 'NO'">
        <!-- master forms -->

        <ValidationProvider
          v-slot="{ errors }"
          name="Forms"
          :rules="{ required: true }"
        >
          <SelectField
            is-mandatory
            label="Forms"
            :value="searchFormId"
            :options="masterForms"
            :is-clearable="false"
            :error="errors[0]"
            class="q-pa-md"
            @input="updateSearchFormId"
          />
        </ValidationProvider>

        <ValidationProvider
          v-if="searchFormId"
          v-slot="{ errors }"
          name="Form Sync Field"
          :rules="{ required: true }"
        >
          <SelectField
            is-mandatory
            label="Form Sync Field"
            :value="masterSyncField"
            :options="masterFormFields"
            :is-clearable="false"
            :error="errors[0]"
            class="q-pa-md"
            @input="updateMasterSyncField"
          />
        </ValidationProvider>

        <!-- ... -->

        <div v-if="searchFormId" class="q-pa-md">
          <FormFieldLabel
            label="Form Fields Sync"
            class="field-label"
            is-mandatory
          />
          <BaseScrollbar>
            <div id="master-table">
              <table>
                <thead>
                  <tr>
                    <th class="medium">Form Fields</th>
                    <th class="medium">Master Form Fields</th>

                    <th class="action">
                      <BaseActionButton
                        is-flat
                        class="q-ml-xs"
                        color="secondary"
                        icon="eva-plus-outline"
                        no-border
                        @click="addMasterFormSync"
                      />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(row, index) in masterFormSyncSettings"
                    :key="index"
                  >
                    <td>
                      <div class="row">
                        <SelectField
                          v-model="masterFormSyncSettings[index]['formField']"
                          class="col"
                          :options="formFields"
                          :is-clearable="false"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="row">
                        <SelectField
                          v-model="masterFormSyncSettings[index]['masterField']"
                          class="col"
                          :options="masterFormFields"
                          :is-clearable="false"
                        />
                      </div>
                    </td>

                    <td class="action">
                      <BaseActionButton
                        is-flat
                        color="red"
                        icon="eva-close-outline"
                        no-border
                        @click="removeMasterFieldSync(index)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </BaseScrollbar>
        </div>
      </template>

      <!-- ... -->
    </template>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import OptionsBuilder from "@/components/common/options-builder/OptionsBuilder.vue";
import TextField from "@/components/common/form/text-field/TextField.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import NumberField from "@/components/common/form/number-field/NumberField.vue";
import CounterField from "@/components/common/form/counter-field/CounterField.vue";
import DateField from "@/components/common/form/date-field/DateField.vue";
import TimeField from "@/components/common/form/time-field/TimeField.vue";
import DateTimeField from "@/components/common/form/date-time-field/DateTimeField.vue";
import SelectField from "@/components/common/form/select-field/SelectField.vue";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import TableColumnsBuilder from "./components/TableColumnsBuilder.vue";
import MatrixVectorsBuilder from "./components/MatrixVectorsBuilder.vue";
import FibFields from "./components/FibFields.vue";
import MatrixType from "./components/MatrixType.vue";
import TabsBuilder from "./components/TabsBuilder.vue";
import NestedListFieldType from "./components/NestedListFieldType.vue";
import NestedListItemsPerLine from "./components/NestedListItemsPerLine.vue";
import MultipleChoiceField from "@/components/common/form/multiple-choice-field/MultipleChoiceField.vue";
import getNewField from "@/helpers/new-field.js";
import { startCase, lowerCase, isEmpty } from "lodash-es";
import { form, workspace, user } from "@/api/factory.js";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import { axiosCrypto } from "@/api/axios.js";

export default {
  name: "SpecificSettings",

  components: {
    ValidationProvider,
    OptionsBuilder,
    TextField,
    TextAreaField,
    NumberField,
    CounterField,
    DateField,
    TimeField,
    DateTimeField,
    SelectField,
    MultiSelectField,
    SingleChoiceField,
    TableColumnsBuilder,
    MatrixVectorsBuilder,
    FibFields,
    MatrixType,
    TabsBuilder,
    NestedListFieldType,
    NestedListItemsPerLine,
    MultipleChoiceField,
    FormFieldLabel,
  },

  props: {
    settingsFor: {
      type: String,
      default: "",
    },

    panels: {
      type: Array,
      default: () => [],
    },

    secondaryPanels: {
      type: Array,
      default: () => [],
    },

    fieldType: {
      type: String,
      default: "",
    },

    optionsType: {
      type: String,
      default: "",
    },

    masterFormId: {
      type: Number,
      default: 0,
    },

    masterFormColumn: {
      type: String,
      default: "",
    },

    masterFormParentColumn: {
      type: String,
      default: "",
    },

    repositoryId: {
      type: Number,
      default: 0,
    },

    repositoryField: {
      type: String,
      default: "",
    },

    repositoryFieldParent: {
      type: String,
      default: "",
    },

    predefinedTable: {
      type: String,
      default: "",
    },

    predefinedTableColumn: {
      type: String,
      default: "",
    },

    customOptions: {
      type: String,
      default: "",
    },

    separateOptionsUsing: {
      type: String,
      default: "",
    },

    allowToAddNewOptions: {
      type: Boolean,
      default: false,
    },

    optionsPerLine: {
      type: Number,
      default: 0,
    },

    defaultValue: {
      type: String,
      default: "",
    },

    customDefaultValue: {
      type: [String, Number, Array, Object],
      default: "",
    },

    autoGenerateValue: {
      type: Object,
      default: () => ({
        prefix: "Form",
        suffix: "DATE_TIME",
      }),
    },

    showColumnTotal: {
      type: Boolean,
      default: false,
    },

    allowMultipleFiles: {
      type: Boolean,
      default: false,
    },

    ratingIcon: {
      type: String,
      default: "",
    },

    ratingIconCount: {
      type: Number,
      default: 0,
    },

    allowHalfRating: {
      type: Boolean,
      default: false,
    },

    allowMultipleSignatures: {
      type: Boolean,
      default: false,
    },

    tableColumns: {
      type: Array,
      default: () => [],
    },

    tableRowChooseType: {
      type: String,
      default: "",
    },

    tableRowsType: {
      type: String,
      default: "",
    },

    tableFixedRowCount: {
      type: Number,
      default: 0,
    },

    matrixColumns: {
      type: Array,
      default: () => [],
    },

    matrixRows: {
      type: Array,
      default: () => [],
    },

    matrixType: {
      type: String,
      default: "",
    },

    matrixTypeSettings: {
      type: Object,
      default: () => {},
    },

    fibFields: {
      type: Array,
      default: () => [],
    },

    minimum: {
      type: [String, Number],
      default: "",
    },

    maximum: {
      type: [String, Number],
      default: "",
    },

    tabList: {
      type: Array,
      default: () => [],
    },

    popupTriggerType: {
      type: String,
      default: "",
    },

    secondaryPanel: {
      type: String,
      default: "",
    },

    mappedFieldId: {
      type: String,
      default: "",
    },

    mappedColumnId: {
      type: String,
      default: "",
    },

    nestedListMaxLevel: {
      type: Number,
      default: 0,
    },

    nestedListFieldType: {
      type: String,
      default: "SHORT_TEXT",
    },

    nestedListItemsPerLine: {
      type: Array,
      default: () => [],
    },

    nestedListTypeSettings: {
      type: Object,
      default: () => {},
    },

    loginType: {
      type: String,
      default: "",
    },

    additionalLoginTypes: {
      type: Array,
      default: () => [],
    },

    qrValue: {
      type: Boolean,
      default: false,
    },

    fieldId: {
      type: String,
      default: "",
    },

    masterFormColumns: {
      type: Array,
      default: () => [],
    },

    parentDateField: {
      type: String,
      default: "",
    },

    parentFieldsDays: {
      type: Number,
      default: 0,
    },

    masterTableColumnsCopy: {
      type: Array,
      default: () => [],
    },

    parentOptionField: {
      type: String,
      default: "",
    },

    dateFieldOptionSettings: {
      type: Array,
      default: () => [],
    },

    isSearchField: {
      type: String,
      default: "NO",
    },

    hasSameForm: {
      type: String,
      default: "YES",
    },

    searchFormId: {
      type: String,
      default: "",
    },

    masterFormSyncSettings: {
      type: Array,
      default: () => [],
    },

    masterSyncField: {
      type: String,
      default: "",
    },

    formSyncField: {
      type: Array,
      default: () => [],
    },

    addressField: {
      type: Boolean,
      default: false,
    },

    addressMatchingFields: {
      type: Array,
      default: () => [],
    },

    defaultValueInSelectField: {
      type: String,
      default: "",
    },

    isSameMaster: {
      type: Boolean,
      default: true,
    },

    masterFormConditionColumn: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      autoGenerateSuffixOptions: [
        {
          id: this.$nano.id(),
          label: "Datetime",
          value: "DATE_TIME",
        },
        {
          id: this.$nano.id(),
          label: "Date",
          value: "DATE",
        },
        {
          id: this.$nano.id(),
          label: "Time",
          value: "TIME",
        },
      ],
      defaultValueOptions: [
        {
          id: this.$nano.id(),
          label: "Logged in user's email",
          value: "USER_EMAIL",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Logged in user's first name",
          value: "USER_NAME",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Logged in user's name",
          value: "LOGIN_NAME",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Current date",
          value: "CURRENT_DATE",
          for: ["DATE"],
        },
        {
          id: this.$nano.id(),
          label: "Current time",
          value: "CURRENT_TIME",
          for: ["TIME"],
        },
        {
          id: this.$nano.id(),
          label: "Current date time",
          value: "CURRENT_DATE_TIME",
          for: ["DATE_TIME"],
        },
        {
          id: this.$nano.id(),
          label: "Auto Generate",
          value: "AUTO_GENERATE",
          for: ["SHORT_TEXT"],
        },
        {
          id: this.$nano.id(),
          label: "Use values from a master table as options",
          value: "MASTER",
          for: [
            "SHORT_TEXT",
            "LONG_TEXT",
            "NUMBER",
            "DATE",
            "TIME",
            "COUNTER",
            "CURRENCY",
            "TABLE",
          ],
        },
        {
          id: this.$nano.id(),
          label: "Use values from a choosing another date",
          value: "PARENT_FIELD",
          for: ["DATE"],
        },
        {
          id: this.$nano.id(),
          label: "Use values from a choosing another field options",
          value: "PARENT_FIELD_OPTIONS",
          for: ["DATE"],
        },
        // {
        //   id: this.$nano.id(),
        //   label: "Use values from a folder data as options",
        //   value: "REPOSITORY",
        //   for: ["SHORT_TEXT"],
        // },
        {
          id: this.$nano.id(),
          label: "Custom",
          value: "CUSTOM",
          for: [
            "SHORT_TEXT",
            "DATE",
            "TIME",
            "DATE_TIME",
            "LONG_TEXT",
            "NUMBER",
            "COUNTER",
            "CURRENCY",
            "COUNTRY_CODE",
          ],
        },
        {
          id: this.$nano.id(),
          label: "None",
          value: "CUSTOM",
          for: ["TABLE"],
        },
      ],
      binaryOptions: [
        {
          id: this.$nano.id(),
          label: "Yes",
          value: true,
        },
        {
          id: this.$nano.id(),
          label: "No",
          value: false,
        },
      ],
      tableRowChooseTypes: [
        {
          id: this.$nano.id(),
          label: "Single Row Selection",
          description: "Allow to select only one row",
          value: "SINGLE_ROW",
        },
        {
          id: this.$nano.id(),
          label: "Multiple Row Selection",
          description: "Allow to select multiple rows",
          value: "MULTIPLE_ROW",
        },
        {
          id: this.$nano.id(),
          label: "None",
          description: "No selection required",
          value: "",
        },
      ],
      tableRowsTypes: [
        {
          id: this.$nano.id(),
          label: "On Demand",
          description: "Add any number of rows on demand",
          value: "ON_DEMAND",
        },
        {
          id: this.$nano.id(),
          label: "Fixed",
          description: "Freeze the number of rows",
          value: "FIXED",
        },
      ],
      ratingIcons: [
        {
          id: this.$nano.id(),
          label: "Star",
          value: "STAR",
          icon: "mdi-star",
          color: "amber",
        },
        {
          id: this.$nano.id(),
          label: "Heart",
          value: "HEART",
          icon: "mdi-heart",
          color: "red",
        },
        {
          id: this.$nano.id(),
          label: "Shield",
          value: "SHIELD",
          icon: "mdi-shield",
          color: "green",
        },
      ],
      popupTriggerTypes: [
        {
          id: this.$nano.id(),
          label: "Button",
          value: "BUTTON",
        },
        {
          id: this.$nano.id(),
          label: "Link",
          value: "LINK",
        },
      ],
      loginTypes: [
        {
          id: this.$nano.id(),
          label: "Default Login",
          description: "APP Accounts to login",
          value: "EZOFIS_LOGIN",
        },
        {
          id: this.$nano.id(),
          label: "Email with OTP ",
          description: "Sends OTP to the Email",
          value: "EMAIL_LOGIN",
        },
        {
          id: this.$nano.id(),
          label: "Mobile with OTP ",
          description: "Sends OTP to the Mobile",
          value: "MOBILE_LOGIN",
        },
      ],
      additionalLoginTypeOptions: [
        {
          id: this.$nano.id(),
          label: "Google",
          value: "GOOGLE_LOGIN",
        },
        {
          id: this.$nano.id(),
          label: "Microsoft",
          value: "MICROSOFT_LOGIN",
        },
      ],
      masterForms: [],
      masterFormName: "",
      masterFormColumns1: [],
      masterFormTableColumns: [],
      parentFieldOptions: [],
      addOptionsIndate: false,
      searchFieldOptions: [
        {
          id: this.$nano.id(),
          label: "Yes",
          value: "YES",
        },
        {
          id: this.$nano.id(),
          label: "No",
          value: "NO",
        },
      ],
      masterFormFields: [],
    };
  },

  computed: {
    showOptionsBuilder() {
      return [
        "SINGLE_SELECT",
        "MULTI_SELECT",
        "SINGLE_CHOICE",
        "MULTIPLE_CHOICE",
      ].includes(this.fieldType);
    },

    showDefaultValue() {
      return [
        "SHORT_TEXT",
        "DATE",
        "TIME",
        "DATE_TIME",
        "LONG_TEXT",
        "NUMBER",
        "COUNTER",
        "CURRENCY",
      ].includes(this.fieldType);
    },

    _defaultValueOptions() {
      return this.defaultValueOptions.filter((option) =>
        option.for.includes(this.fieldType)
      );
    },

    _customOptions() {
      if (!this.customOptions) {
        return [];
      }

      const separator = this.separateOptionsUsing === "NEWLINE" ? "\n" : ",";

      return this.customOptions.split(separator).map((option) => ({
        id: this.$nano.id(),
        label: option.trim(),
        value: option.trim(),
      }));
    },

    _showColumnTotal() {
      if (this.settingsFor !== "TABLE") {
        return false;
      }

      if (["NUMBER", "COUNTER", "CALCULATED"].includes(this.fieldType)) {
        return true;
      }

      return false;
    },

    ratingIconCounts() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => ({
        id: this.$nano.id(),
        label: `${count}`,
        value: count,
      }));
    },

    _secondaryPanels() {
      return this.secondaryPanels.map((panel, idx) => {
        const label = `${idx + 1}. ${panel.settings.title || "Panel title"}`;

        return {
          id: this.$nano.id(),
          label: label,
          value: panel.id,
        };
      });
    },

    secondaryPanelFields() {
      if (!this.secondaryPanel) return [];

      const secondaryPanel = this.secondaryPanels.find(
        (panel) => panel.id === this.secondaryPanel
      );

      return secondaryPanel.fields
        .filter((field) => {
          if (field.type === "TABLE") {
            return field.settings.specific.tableColumns.some((column) =>
              ["CALCULATED", "NUMBER", "COUNTER"].includes(column.type)
            );
          }

          if (field.type === "NESTED_LIST") {
            return ["NUMBER", "COUNTER"].includes(
              field.settings.specific.nestedListFieldType
            );
          }

          return ["CALCULATED", "NUMBER", "COUNTER"].includes(field.type);
        })
        .map((field) => ({
          id: field.id,
          label: field.label || startCase(lowerCase(field.type)),
          value: field.id,
        }));
    },

    secondaryPanelFieldColumns() {
      if (!this.mappedFieldId) return [];
      if (isEmpty(this.secondaryPanelFields)) return [];

      const secondaryPanel = this.secondaryPanels.find(
        (panel) => panel.id === this.secondaryPanel
      );

      const field = secondaryPanel.fields.find(
        (_field) => _field.id === this.mappedFieldId
      );

      return field.settings.specific.tableColumns
        .filter((column) =>
          ["CALCULATED", "NUMBER", "COUNTER"].includes(column.type)
        )
        .map((column) => ({
          id: column.id,
          label: column.label || startCase(lowerCase(column.type)),
          value: column.id,
        }));
    },

    _masterFormColumns() {
      if (this.masterFormColumns.length) {
        return this.masterFormColumns;
      }
      return this.masterFormColumns1;
    },

    parentDateFields() {
      if (!this.panels) return [];
      let fieldList = [];
      let fields = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }

      fieldList.forEach((field) => {
        // console.log(field);

        if (field.id !== this.fieldId) {
          if (field.type === "DATE") {
            fields.push({
              id: this.$nano.id(),
              label: field.label,
              value: field.id,
            });
          }
        }
      });

      return fields;
    },

    _tableColumns() {
      return this.tableColumns.map((table) => ({
        id: this.$nano.id(),
        label: table.label,
        value: table.id,
      }));
    },

    parentOptionsFields() {
      if (!this.panels) return [];
      let fieldList = [];
      let fields = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }

      fieldList.forEach((field) => {
        // console.log(field);

        if (field.id !== this.fieldId) {
          if (
            field.type === "SINGLE_SELECT" ||
            field.type === "MULTI_SELECT" ||
            field.type === "SINGLE_CHOICE" ||
            field.type === "MULTIPLE_CHOICE"
          ) {
            fields.push({
              id: this.$nano.id(),
              label: field.label,
              value: field.id,
            });
          }
        }
      });

      return fields;
    },

    showSearchableOption() {
      if (this.settingsFor === "NORMAL") {
        return ["SHORT_TEXT", "NUMBER"].includes(this.fieldType);
      }
      return false;
    },

    formFields() {
      if (!this.panels) return [];
      let fieldList = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }

      let parentFields = [];
      fieldList.forEach((field) => {
        // console.log(field);
        if (this.settingsFor === "NORMAL") {
          if (
            field.type !== "DIVIDER" &&
            field.type !== "CALCULATED" &&
            field.type !== "PARAGRAPH" &&
            field.type !== "LABEL"
          ) {
            if (field.id !== this.fieldId) {
              parentFields.push({
                id: this.$nano.id(),
                label: field.label,
                value: field.id,
              });
            }
          }
        }
      });
      // console.log(parentFields);
      return parentFields;
    },

    showQROption() {
      return [
        "SHORT_TEXT",
        "NUMBER",
        "TABLE",
        "SINGLE_SELECT",
        "SINGLE_CHOICE",
      ].includes(this.fieldType);
    },

    _panelFields() {
      if (!this.panels) return [];
      let fieldList = [];
      let fieldControls = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }
      fieldList.forEach((field) => {
        if (!this.isStatelessField(field.type)) {
          if (field.id !== this.fieldId) {
            if (field.type === "TABLE") {
              fieldControls.push({
                id: this.$nano.id(),
                label: field.label ? field.label + " (TABLE)" : field.type,
                value: field.id,
              });
            } else {
              fieldControls.push({
                id: this.$nano.id(),
                label: field.label ? field.label + " (Control)" : field.type,
                value: field.id,
              });
            }
          }
        }
      });
      return fieldControls;
    },
  },

  watch: {
    masterFormId: {
      handler() {
        // console.log(this.masterFormId);
        if (this.defaultValue === "MASTER" && this.masterFormId) {
          // console.log(this.masterFormId);
          this.getMasterForms(this.masterFormId);
        }
      },
    },

    masterFormTableColumns: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit(
          "update:masterFormTableColumns",
          this.masterFormTableColumns
        );
      },
    },

    dateFieldOptionSettings: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit(
          "update:dateFieldOptionSettings",
          this.dateFieldOptionSettings
        );
      },
    },

    parentOptionField: {
      immediate: true,
      handler() {
        if (this.parentOptionField) {
          console.log(this.parentOptionField, "parentOptionField");
          let hasField = true;
          this.parentFieldOptions = [];
          this.panels.forEach((panel) => {
            if (hasField) {
              let isField = panel.fields.find(
                (field) => field.id === this.parentOptionField
              );
              console.log(isField);
              if (isField) {
                hasField = false;
                const optionsType = isField.settings.specific.optionsType;
                if (optionsType === "CUSTOM") {
                  let options = isField.settings.specific.customOptions;

                  const separator =
                    isField.settings.specific.separateOptionsUsing === "NEWLINE"
                      ? "\n"
                      : ",";

                  this.parentFieldOptions = options
                    .split(separator)
                    .map((option) => ({
                      id: this.$nano.id(),
                      label: option.trim(),
                      value: option.trim(),
                    }));
                } else if (optionsType === "PREDEFINED") {
                  if (isField.settings.specific.predefinedTable === "User") {
                    this.getUsers();
                  } else if (
                    isField.settings.specific.predefinedTable === "Workspace"
                  ) {
                    this.getWorkspace();
                  }
                } else if (optionsType === "MASTER") {
                  this.getMasterEntries(
                    isField.settings.specific.masterFormId,
                    isField.settings.specific.masterFormColumn
                  );
                } else if (optionsType === "REPOSITORY") {
                  this.getRepositoryData(
                    isField.settings.specific.repositoryId,
                    isField.settings.specific.repositoryField
                  );
                } else if (
                  optionsType === "EXISTING" &&
                  isField.settings.specific.allowToAddNewOptions
                ) {
                  this.addOptionsIndate = true;
                }
              }
            }
          });
        }
      },
    },

    hasSameForm: {
      immediate: true,
      handler() {
        if (this.hasSameForm === "NO") {
          this.getMasterFormsList();
        }
      },
    },

    searchFormId: {
      immediate: true,
      handler() {
        if (this.searchFormId) {
          this.getMasterFormsFields();
        }
      },
    },

    masterFormSyncSettings: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit(
          "update:masterFormSyncSettings",
          this.masterFormSyncSettings
        );
      },
    },

    addressMatchingFields: {
      immediate: true,
      deep: true,
      handler: function () {
        this.$emit("update:addressMatchingFields", this.addressMatchingFields);
      },
    },
  },

  created() {
    if (this.defaultValue === "MASTER" && this.masterFormId) {
      this.getMasterForms(this.masterFormId);
      this.getFormFields();
    }
    if (this.masterTableColumnsCopy.length) {
      this.masterTableColumnsCopy.forEach((item) => {
        this.masterFormTableColumns.push(item);
      });
    } else {
      if (!this.masterFormTableColumns.length) {
        this.tableColumns.forEach((table) => {
          this.masterFormTableColumns.push({
            id: this.$nano.id(),
            tableColumn: table.id,
            masterColumn: "",
            mandatoryColumn: "",
            mandatoryValue: "",
          });
        });
      }
    }

    if (this.dateFieldOptionSettings.length === 0) {
      this.addDateFieldOption();
    }

    if (this.masterFormSyncSettings.length === 0) {
      this.addMasterFormSync();
    }
  },

  methods: {
    isStatelessField(fieldType) {
      return ["LABEL", "DIVIDER", "PARAGRAPH", "TABLE"].includes(fieldType);
    },

    updateDefaultValue(option) {
      this.$emit("update:defaultValue", option);
    },

    updateCustomDefaultValue(value) {
      this.$emit("update:customDefaultValue", value);
    },

    updateAutoGenerateValue(value) {
      this.$emit("update:autoGenerateValue", value);
    },

    updateShowColumnTotal(value) {
      this.$emit("update:showColumnTotal", value);
    },

    updateAllowMultipleFiles(value) {
      this.$emit("update:allowMultipleFiles", value);
    },

    updateRatingIcon(icon) {
      this.$emit("update:ratingIcon", icon);
    },

    updateRatingIconCount(count) {
      this.$emit("update:ratingIconCount", count);
    },

    updateAllowHalfRating(value) {
      this.$emit("update:allowHalfRating", value);
    },

    updateAllowMultipleSignatures(value) {
      this.$emit("update:allowMultipleSignatures", value);
    },

    updateTableColumns(columns) {
      this.$emit("update:tableColumns", columns);
    },

    updateMatrixColumns(columns) {
      this.$emit("update:matrixColumns", columns);
    },

    updateMatrixRows(rows) {
      this.$emit("update:matrixRows", rows);
    },

    updateMatrixType(type) {
      this.$emit("update:matrixType", type);
      this.$emit("update:matrixTypeSettings", getNewField(type));
    },

    showColumnSettings(column) {
      this.$emit("column-settings", column);
    },

    showMatrixTypeSettings() {
      this.$emit("matrix-type-settings");
    },

    showNestedListTypeSettings() {
      this.$emit("nested-list-type-settings");
    },

    updateTabList(tabs) {
      this.$emit("update:tabList", tabs);
    },

    updatePopupTriggerType(type) {
      this.$emit("update:popupTriggerType", type);
    },

    updateSecondaryPanel(panelIdx) {
      this.$emit("update:secondaryPanel", panelIdx);
    },

    updateMappedFieldId(fieldId) {
      this.$emit("update:mappedFieldId", fieldId);
    },

    updateMappedColumnId(columnId) {
      this.$emit("update:mappedColumnId", columnId);
    },

    updateNestedListMaxLevel(level) {
      this.$emit("update:nestedListMaxLevel", level);
    },

    updateNestedListFieldType(type) {
      this.$emit("update:nestedListFieldType", type);
      this.$emit("update:nestedListTypeSettings", getNewField(type));
    },

    updateNestedListItemsPerLine(value) {
      this.$emit("update:nestedListItemsPerLine", value);
    },

    updateTableRowChooseType(type) {
      this.$emit("update:tableRowChooseType", type);
    },

    updateTableRowsType(type) {
      this.$emit("update:tableRowsType", type);
    },

    updateTableFixedRowCount(count) {
      this.$emit("update:tableFixedRowCount", count);
    },

    updateLoginType(type) {
      this.$emit("update:loginType", type);
    },

    updateAdditionalLoginTypes(value) {
      this.$emit("update:additionalLoginTypes", value);
    },

    updateQRValue(option) {
      this.$emit("update:qrValue", option);
    },

    updateAddressField(option) {
      this.$emit("update:addressField", option);
    },

    addRow() {
      const model = {
        id: this.$nano.id(),
        value: "",
        controls: [],
      };

      this.addressMatchingFields.push(model);
    },

    removeRow(rowIdx) {
      this.addressMatchingFields.splice(rowIdx, 1);
    },

    panelFields() {
      if (!this.panels) return [];
      let fieldList = [];
      for (const panel of this.panels) {
        fieldList.push(...panel.fields);
      }

      let parentFields = [];
      fieldList.forEach((field) => {
        // console.log(field);
        if (this.settingsFor === "NORMAL") {
          if (field.id !== this.fieldId) {
            if (
              field.type === "SINGLE_SELECT" ||
              field.type === "MULTI_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE"
            ) {
              if (field.settings.specific.masterFormId) {
                // if (
                //   field.settings.specific.masterFormId === this.masterFormId
                // ) {

                parentFields.push({
                  id: this.$nano.id(),
                  label: field.label,
                  value: field.id,
                });
                // }
              } else if (field.settings.specific.repositoryId) {
                if (
                  field.settings.specific.repositoryId === this.repositoryId
                ) {
                  parentFields.push({
                    id: this.$nano.id(),
                    label: field.label,
                    value: field.id,
                  });
                }
              }
            }
          }
        } else if (this.settingsFor === "TABLE") {
          if (field.type === "TABLE") {
            field.settings.specific.tableColumns.forEach((tblField) => {
              if (tblField.id !== this.fieldId) {
                if (
                  tblField.type === "SINGLE_SELECT" ||
                  tblField.type === "MULTI_SELECT" ||
                  tblField.type === "SINGLE_CHOICE" ||
                  tblField.type === "MULTIPLE_CHOICE"
                ) {
                  if (tblField.settings.specific.masterFormId) {
                    parentFields.push({
                      id: this.$nano.id(),
                      label: tblField.label,
                      value: tblField.id,
                    });
                  }
                }
              }
            });
          }
        }
      });
      // console.log(parentFields);
      return parentFields;
    },

    updateMasterFormParentColumn(masterFormParentColumn) {
      this.masterFormName = "";
      this.$emit("update:masterFormParentColumn", masterFormParentColumn);

      if (this.masterFormId && masterFormParentColumn)
        this.getMasterForms(this.masterFormId);
    },

    async getMasterForms(id) {
      this.$store.commit("ShowLoadingBar");
      const { error, payload } = await form.getFormById(id);
      this.$store.commit("HideLoadingBar");

      if (error) {
        this.$alert.error("Error fetching master form");
        return;
      }
      // console.log(payload);

      this.masterForms = [];

      if (payload) {
        this.masterFormName = payload.name;
      }
    },

    updateMasterFormColumn(masterFormColumn) {
      this.$emit("update:masterFormColumn", masterFormColumn);
    },

    async getFormFields() {
      this.masterFormColumns1 = [];
      const { error, payload } = await form.getForm(this.masterFormId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let form = JSON.parse(payload.formJson);
        let masterfields = [];
        const panels = [...form.panels, ...form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          masterfields.push(...panel.fields);
        }

        masterfields.forEach((field) => {
          if (field.type !== "DIVIDER") {
            if (this.fieldType === "TABLE") {
              if (field.type === "TABLE") {
                // console.log(field);
                field.settings.specific.tableColumns.forEach((table) => {
                  this.masterFormColumns1.push({
                    id: this.$nano.id(),
                    label: table.label ? table.label + " (TABLE)" : table.type,
                    value: table.id,
                  });
                });
              } else {
                this.masterFormColumns1.push({
                  id: this.$nano.id(),
                  label: field.label ? field.label + " (Control)" : field.type,
                  value: field.id,
                });
              }
            } else {
              this.masterFormColumns1.push({
                id: field.id,
                label: field.label,
                value: field.id,
              });
            }
          }
        });
      }
    },

    updateParentDateField(parentDateField) {
      this.$emit("update:parentDateField", parentDateField);
    },

    updateParentFieldsDays(parentFieldsDays) {
      this.$emit("update:parentFieldsDays", parentFieldsDays);
    },

    updateParentOptionField(parentOptionField) {
      this.$emit("update:parentOptionField", parentOptionField);
    },

    addDateFieldOption() {
      const model = {
        id: this.$nano.id(),
        option: "",
        dateField: "",
        days: 0,
      };

      this.dateFieldOptionSettings.push(model);
    },

    removeDateFieldOption(rowIdx) {
      this.dateFieldOptionSettings.splice(rowIdx, 1);
    },

    async getUsers() {
      const { error, payload } = await user.getUserList();
      if (error) {
        this.$alert.error(error);
        return;
      }
      if (!payload) {
        return;
      }
      this.parentFieldOptions = payload.map((user) => ({
        id: this.$nano.id(),
        label: user.value || user.loginName,
        value: user.value || user.loginName,
      }));
    },

    async getWorkspace() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workspace.getWorkspaceList();

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      if (!payload) {
        return;
      }

      this.parentFieldOptions = payload.map((workspace) => ({
        id: this.$nano.id(),
        label: workspace.value,
        value: workspace.value,
      }));
    },

    async getMasterEntries(formId, column) {
      try {
        const response = await axiosCrypto.post(
          `/form/${formId}/uniqueColumnValues`,
          JSON.stringify({
            column: column,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.parentFieldOptions = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    async getRepositoryData(repositoryId, field) {
      try {
        const response = await axiosCrypto.post(
          `/repository/${repositoryId}/uniqueColumnValues`,
          JSON.stringify({
            column: field,
            keyword: "",
            rowFrom: 0,
            rowCount: 0, // + 10,
          })
        );
        const { status, data } = response;
        if (status !== 200) {
          throw response;
        }
        const options = JSON.parse(data);
        if (options.length) {
          this.parentFieldOptions = options.map((option) => ({
            id: this.$nano.id(),
            label: option,
            value: option,
          }));
        }
      } catch (e) {
        console.error(e);
        this.$alert.error("Error fetching options");
      }
    },

    updateIsSearchField(isSearchField) {
      this.$emit("update:isSearchField", isSearchField);
    },

    updateHasSameForm(hasSameForm) {
      this.$emit("update:hasSameForm", hasSameForm);
    },

    updateSearchFormId(searchFormId) {
      this.$emit("update:searchFormId", searchFormId);
    },

    async getMasterFormsList() {
      const { error, payload } = await form.getForms({
        mode: "BROWSE",
        sortBy: { criteria: "", order: "DESC" },
        groupBy: "",
        filterBy: [
          {
            filters: [
              {
                criteria: "type",
                condition: "IS_EQUALS_TO",
                value: "MASTER",
                dataType: "",
              },
              {
                criteria: "publishOption",
                condition: "IS_EQUALS_TO",
                value: "PUBLISHED",
                dataType: "",
              },
            ],
            groupCondition: "",
          },
        ],
        itemsPerPage: 500,
        currentPage: 1,
        hasSecurity: false,
      });

      if (error) {
        this.$alert.error("Error fetching master form list");
        return;
      }

      this.masterForms = [];
      const { data } = payload;
      if (data.length) {
        this.masterForms = data[0].value.map((form) => ({
          id: this.$nano.id(),
          label: form.name,
          value: `${form.id}`,
        }));
      }
    },

    async getMasterFormsFields() {
      this.masterFormFields = [];
      const { error, payload } = await form.getForm(this.searchFormId);

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        let form = JSON.parse(payload.formJson);
        let masterfields = [];
        const panels = [...form.panels, ...form.secondaryPanels];

        if (!panels.length) {
          return;
        }
        for (const panel of panels) {
          masterfields.push(...panel.fields);
        }

        masterfields.forEach((field) => {
          if (
            field.type !== "DIVIDER" &&
            field.type !== "CALCULATED" &&
            field.type !== "PARAGRAPH" &&
            field.type !== "LABEL"
          ) {
            if (this.fieldType === "TABLE") {
              if (field.type === "TABLE") {
                // console.log(field);
                field.settings.specific.tableColumns.forEach((table) => {
                  this.masterFormFields.push({
                    id: this.$nano.id(),
                    label: table.label ? table.label + " (TABLE)" : table.type,
                    value: table.id,
                  });
                });
              } else {
                this.masterFormFields.push({
                  id: this.$nano.id(),
                  label: field.label ? field.label + " (Control)" : field.type,
                  value: field.id,
                });
              }
            } else {
              this.masterFormFields.push({
                id: field.id,
                label: field.label,
                value: field.id,
              });
            }
          }
        });
      }
    },

    addMasterFormSync() {
      const model = {
        id: this.$nano.id(),
        formField: "",
        masterField: "",
      };

      this.masterFormSyncSettings.push(model);
    },

    removeMasterFieldSync(rowIdx) {
      this.masterFormSyncSettings.splice(rowIdx, 1);
    },

    updateMasterSyncField(masterSyncField) {
      this.$emit("update:masterSyncField", masterSyncField);
    },

    updateFormSyncField(formSyncField) {
      this.$emit("update:formSyncField", formSyncField);
    },
  },
};
</script>

<style lang="scss" scoped>
#master-table,
#address-matching-field {
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;

    tr {
      height: 48px;
    }

    th:not(.action) {
      padding: 4px 8px;
      text-align: left;
      font-weight: 500;
      text-transform: capitalize;

      &.small {
        width: 80px;
      }

      &.medium {
        width: 150px;
      }

      &.large {
        width: 210px;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      &.action {
        width: 36px;
      }
    }

    td {
      padding: 4px;
      vertical-align: top;
    }
  }
}
</style>
